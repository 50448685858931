<template>
    <div>
        <van-nav-bar
            title="种植档案"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div class="planting">
            <div class="planting-info">
                <div>{{this.name}}</div>
                <div>{{plantingDetailsFrom.crop_name}}</div>
                <div>第{{plantingFrom.code}}号</div>
            </div>
            <div class="planting-cropType">
                <div style="width:15vw;">品种:</div>
                <div>{{plantingDetailsFrom.crop_type_name}}</div>
                <div>{{plantingFrom.status_name}}</div>
            </div>
            <div class="planting-date">
                <div style="width:15vw;">成熟:</div>
                <div>{{plantingFrom.maturity_date}}</div>
                <div>还有{{plantingFrom.to_maturity_days}}天</div>
            </div>
            <div class="planting-date-info">
                <div style="width:20vw">果实:</div>
                <div style="white-space:pre-wrap;">{{plantingDetailsFrom.info}}</div>
            </div>
            <div class="planting-date-info">
                <div style="width:20vw">产量:</div>
                <div style="white-space:pre-wrap;">{{plantingFrom.output}}</div>
            </div>
            <div class="planting-date-info">
                <div style="width:20vw">树况:</div>
                <div style="white-space:pre-wrap;" v-if="show">{{text}}123</div>
                <div v-else><input type="text" v-model="text" style="width:100px"></div>
            </div>
            <div class="planting-data">
                <div style="width:20vw;">定值:</div>
                <div>{{plantingFrom.date}}</div>
            </div>
            <div class="planting-age">
                <div style="width:20vw;">树龄:</div>
                <div>{{plantingFrom.age}}</div>
            </div>
            <div class="planting-address">
                <div style="width:20vw;">位置:</div>
                <div>{{address}}</div>
                <div style="margin-left:3vw" @click="toMap">查看地图</div>
            </div>
            <div class="planting-uploader">
                <van-uploader multiple :before-read="uploadImg" v-model="fileList" />
            </div>
        </div>
        <div class="planting-button">
            <van-button type="info" round size="large" @click="onClickLeft">关闭</van-button>
        </div>
    </div>
</template>

<script>
import { NavBar, Uploader, Button } from "vant"
import { plantTreeGetById, LandById } from "../../service/archives/archivesDetails"
export default {
    name: "PlantingDetails",
    data() {
        return {
            name: "",
            plantingFrom: {},
            plantingDetailsFrom: {},
            address: "",
            fileList: [],
            image: [],
            show: true,
            text: ""
        }
    },
    components: {
        "van-nav-bar": NavBar,
        VanUploader: Uploader,
        VanButton: Button
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        async init() {
            const id = this.$route.params && this.$route.params.id
            const res = await plantTreeGetById(id)
            this.plantingFrom = res.data
            this.plantingDetailsFrom = res.data.plantFile
            this.image = res.data.plantFile.imageUrl
            this.GetLandById(res.data.plantFile.land_id)
            this.text = res.data.condition
            var isimage = true
            for (var i = 0; i < this.image.length; i++) {
                var url = this.image[i]
                this.fileList.push({ isimage, url })
            }
        },
        async GetLandById(id) {
            const res = await LandById(id)
            this.name = res.data.land.name
            this.address = `${res.data.land.province_name} - ${res.data.land.city_name} - ${res.data.land.county_name}`
            this.longitude = res.data.land.longitude
            this.latitude = res.data.land.latitude
        },
    toMap() {
        this.$router.push({
            name: "ArchivesMap",
            params: {
                latitude: this.latitude,
                longitude: this.longitude,
                name: this.name,
                position: this.address
            }
        })
        },
        uploadImg(file, detail) {
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
    .planting{
        margin: 1vh 2vw;
        div{
            margin-top: 2vh;
        }
        .planting-info{
            display:flex;
            justify-content: space-between;
            width: 75%;
        }
        .planting-cropType{
            display:flex;
            justify-content: space-between;
            width: 51%;
        }
        .planting-date{
            display:flex;
            justify-content: space-between;
            width: 65%;
        }
        .planting-date-info{
            display:flex;
            width: 70vw;
        }
        .planting-data {
            display: flex;
        }
        .planting-age {
            display: flex
        }
        .planting-address {
            display: flex;
            width: 100%;
        }
        .planting-uploader {
            margin-top: 3vh;
        }
        .van-button {
            margin-top: 4vh;
        }
    }
    .planting-button {
        margin-top: 4vh;
    }
</style>